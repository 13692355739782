.home {
  margin-top: 20vh;
}

.headshot img{
  width: 300px; 
  border-radius: 50%;
  border: 1px solid black;
}

.project-container {
  flex-direction: column;
  margin-top: 20vh;
}

.name {
  font-size: 10vh;
}

.about {
  font-weight: bold;
  text-align: justify;
  margin: 20vh 5vw 0 5vw;
  text-indent: 30px;
  line-height: 25px;
}

.contact {
  font-weight: bold;
  margin-top: 20vh;
}

.contact p {
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.languages {
  font-weight: bold;
}

/* contact form */

form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-width: 500px;
  width: 80vw;
  height: 400px;
  margin: 30px auto;
  text-align: left;
  background-color: white;
}

form div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

input {  
  width: 80%;
  outline: 0;
  padding: 5px;
}

textarea {
  width: 80%;
  outline: 0;
}

label {
  width: 20%;
}

.text-box {
  align-items: flex-start;
}

@media screen and (max-width: 500px) {
  label {
    font-size: 3vw;
  }

  .name {
    font-size: 12vw;
  }

  .home:last-child {
    font-size: 3.25vw;
  }
}
