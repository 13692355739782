.layout {
  height: 100%;
  width: 100vw;
  display: flex;
  background-color: rgba(245, 245, 245, 0.7);
}

.layout-children {
  flex-direction: column;
  width: 90vw;
  margin-left: 10vw;
  min-height: 100vh;
}

/* media queries */

@media screen and (max-width: 700px) {
  .layout-children {
    width: 100vw;
    margin-left: 0vw;
  }
}