.App {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  color: #2f3e46;
}

a {
  color: #52796f;
  text-decoration: none;
}

a:visited {
  color: #52796f;
}

button {
  font-size: 100%;
  background-color: transparent;
  color: #84a98c;
  font-weight: bold;
  padding: 5px;
  margin: 3px;
  font-family: "Open Sans", sans-serif;
  border: none;
}

button:hover {
  color: #000000;
  background-color: #84a98c;
}

#send:hover {
  background-color: transparent;
}

h3 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 40px;
}

h2 {
  font-size: 30px;
  text-align: center;
  margin: 10px;
}

.inline:hover {
  text-decoration: underline;
}

.icons {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.icons img {
  height: 70px;
  margin: 10px;
}

.links {
  text-align: center;
}

.languages {
  display: flex;
  flex-flow: column wrap;
  height: 35vh;
  margin: 3vh auto;
  width: 80vw;
}

li {
  list-style-type: circle;
  margin-right: 0px;
  list-style-position: inside;
}

@media screen and (max-width: 700px) {
  h2 {
    font-size: 4.5vw;
  }
}

@media screen and (max-width: 550px) {
  .languages {
    height: unset;
  }
}
